body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
  font-family: Arial, sans-serif;
  /*font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;*/
  /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",*/
  /*"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",*/
  /*sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: white;
}

.closeIconClass{
  height: 25px;
  width: 25px;
  float: right;
  margin: 0 auto;
  cursor: pointer;
  text-align: center;
}

.closeIconClass:hover{
  background-color: #DCDCDC;
  border-radius: 50%;
}

.closeIconClass > i{
  padding: 0;
}

.thinHeading{
  font-weight: 300;
}

.bannerAlert {
  min-height: 80px;
  background: #FFFFFF;
  padding: 16px;
  border-radius: 4px;
  display: grid;
  grid-template-columns: auto 1fr auto;
}

.bannerIcon{
  margin-right: 10px;
}

#hover-black:hover{
  color: #161E16;
}

.hover:hover{
  box-shadow: 0 16px 32px 0 rgba(0,0,0,0.4);
}

.wordwrap{
  word-break: break-all;
  word-wrap: break-word;
}

.wordwrap2{
  white-space: pre-wrap;      /* CSS3 */
  white-space: -moz-pre-wrap; /* Firefox */
  /*white-space: -pre-wrap;     !* Opera <7 *!*/
  white-space: -o-pre-wrap;   /* Opera 7 */
  word-wrap: break-word;      /* IE */
}

.ellipses{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*Signup*/
.signupBG{
  background-color: #e6e6e6;
  color: white;
  border-radius: 4px;
  padding: 6px 8px;
  border: none;
  outline: none;
  color: #161E16;
  margin-bottom: 5px;
  margin-top: 5px;
  
}

.signupBox{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.signupButton{
  background-color: #007BFF; 
  color: white; 
  padding: 10px 20px; 
  border: none; 
  border-radius: 4px; 
  outline: none;
  cursor: pointer; 
  margin: 5px;
}

.signupButton:hover {
  background-color: #0056b3; 
  outline: none;
}

.signup-entry {
  margin: 10px;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.signupTitle {
  padding: 10px;
  color: white;
  background-color: #007BFF;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  
}


.chat-container {
  display: grid;
  grid-template-columns: 1fr; /* Sidebar and Main Content */
  grid-template-rows: 1fr auto; /* Main Content and Bottom Section */
  grid-template-areas:
      "main-content"
      "bottom-section";
  min-height: 100vh;
}

.sidebar {
  grid-area: sidebar;
  background-color: #212529;
  color: #fff;
  padding: 20px;
}

.main-content {
  grid-area: main-content;
  color: white;
  height: 100%;
  background-color: #343a40;
  overflow-y: auto;
  
  position: relative;
  z-index: 0;
}

.main-content-wcontent {
  max-width: 100%;
  height: 100%;
}

.bottom-section {
  position: fixed;
  bottom: 0;
  grid-area: bottom-section;
  background: linear-gradient(to bottom, rgba(111, 124, 136, 0), rgb(34, 38, 42));
  background-color: transparent;
  padding: 60px 24px 24px 24px;
  height: 140px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  
}

.bottom-section-shortened {
  height: 220px; /*height of bottom-section will be 220 until sample prompts dissappear.*/ 
}



.chat-loading {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.prompt-footer {
  background-color: #343a40;
  padding: 20px;
  height: 120px;
  width: 100%;
  display: block;
  bottom: 0; 
  position: relative;
}

.prompt {
  background-color: #fff;
  padding: 20px;
  width: 100%;
  display: block; 
  
}

.answer {
  background-color: #f2f2f2;
  padding: 20px;
  width: 100%;
  display: block; 
}

.chat-entry {
  display: block;
  width: 100%;
}

.chat-flex {
  display: flex;
  justify-content: start; 
  align-items: center; 
  flex-direction: row;
}

.profile-box {
  padding: 5px;
  align-self: flex-start;
}

.chat-box {
  padding: 5px;
  
}

.chat-contactus {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 5px;
  text-align: center;
  align-items: center;
  margin-bottom: 5px;
}

.chat-contactbutton {
  background: #5fca62;
  border: 1px solid #5fca62;
  text-align: center;
  padding: 10px;
  border-radius: 6px;
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  margin-left: 4px;
  margin-top: 5px;
}

.chat-contactbutton:hover{
  background-color: #62cd65;
  box-shadow: 0 1px 6px rgba(32,33,36,.28);
  border-color: rgba(223,225,229,0);
}

.chat-contactbutton:focus {
  border: 1px solid #5fca62;
  outline: none;
}

.profile-image {
  width: 40px; 
  height: 40px; 
  border-radius: 50%; 
  overflow: hidden; 
  
}

.profile-image img {
  width: 100%; 
  height: auto; 
  
}


/*New layout grid */
.grid-container {
  display: grid;
  grid-template-rows: auto auto auto 1fr 0.1fr; 
  grid-template-columns: 1fr; 
  height: 100vh; 
}

.navigation {
  background-color: #fff;
  color: #515151;
  padding: 20px;
}

.header {
  background-color: #fff;
  color: #515151;
  /*padding: 20px;*/
  
}

.chat {
  background-color: #fff;
  padding: 5px;
 
}

.sample-prompts {
  background-color: #fff;
  padding: 20px;
  
}

.footer {
  background-color: #fff;
  color: #515151;
  padding: 20px;
  text-align: center;
  font-size: 12px;
}

/*NEW LAYOUT*/
.header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.logo {
  width: 160px;
  height: 80px;
  background-image: url("https://predanalytics-assets.s3.amazonaws.com/NewLogo.webp");
  /*background-image: url("https://predanalytics-assets.s3.amazonaws.com/logotest.webp"); */
  background-repeat: no-repeat;
  /*margin-top: 14px;*/
  background-size: contain;
}
html {
  height: 100%
}
.banner{
  /*background-repeat: no-repeat;*/
  /*background-size: 100% 100%;*/
  /*background-image: url("https://predanalytics-assets.s3.amazonaws.com/web-banner-browser.svg");*/
}

.motto {
  font-size: 20px;
  padding: 10px;
  margin-bottom: 20px;
}

.info {
  font-size: 16px;
  padding: 12px;
  margin-top: 16px;
}

.infodesc {
  font-size: 13.5px;
  margin-bottom: 5px;
}

.signup-container {
  display:flex;
  flex-direction: column;
  margin-top: 20px;
  color: #515151;
  width: 100%;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 5px;
}

.signup-info {
  font-size: 24px;
  margin-bottom: 7px;
}

.signup-textarea {
  font-size: 14px;
}

.input-signup {
  flex-grow:2;
  border:none;
  background: #fff;
  color: #515151;
  resize: none;
  border-radius: 24px;
  padding: 10px;
  border: solid 1px #dfe1e5;
  width: 250px;
  text-align: center;
}

.input-signup:hover{
  background-color: #fff;
  box-shadow: 0 1px 6px rgba(32,33,36,.28);
  border: solid 1px #dfe1e5;
}

.input-signup:focus-within {
  outline: none;
}

.input-signup:active {
  outline: none;
}

.input-signup:focus {
  outline: none;
}

.signup-button {
  background: #49B555;
  margin-left: 3px;
  border: 1px solid #49B555;
  color: #fff;
  border-radius: 24px;
  padding: 9px;
  width: auto;
}

.signup-button:hover{
  box-shadow: 0 1px 6px rgba(32,33,36,.28);
  border: 1px solid #49B555;
}

.signup-icon {
  border: none;
  border-radius: 20px;
  background:none;
  color:white;
  width: 40px;
  background-image: url("https://predanalytics-assets.s3.amazonaws.com/signup.webp");
  background-size: 25px;
  background-repeat: no-repeat;
  background-position: center center;
}

.error-message {
  color: #fff;
  background-color: #BD4747;
  width: 255px;
  border-radius: 24px;
  padding: 6px;
  margin-bottom: 5px;
}

.success-message {
  color: #fff;
  background-color: #47BD53;
  width: 255px;
  border-radius: 24px;
  padding: 6px;
  margin-bottom: 5px;
}

.contactbutton {
  background: #2870ea;
  border: 1px solid #3377ee;
  text-align: center;
  padding: 10px;
  border-radius: 6px;
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  margin-left: 4px;
  
}

.contactbutton:hover{
  background-color: #2870ea;
  box-shadow: 0 1px 6px rgba(32,33,36,.28);
  border-color: rgba(223,225,229,0);
}

.contactbutton:focus {
  border: 1px solid #2464d2;
  outline: none;
}

.signup-button:focus {
  border: none;
  outline: none;
}

.display-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
}

.display-box {
  width: 60%;
  border: 1px solid #dfe1e5;
  font-size: 15px;
  color: #515151;
  border-radius: 21px;
  background: #f2f2f2;
  transition: max-height 0.3s;
}

.display-box:hover {
  box-shadow: 0 1px 6px rgba(32,33,36,.28);
  border-color: rgba(223,225,229,0);
}

.textarea-box {
  width: 100%;
}

.chatarea-box {
  background: #f2f2f2;
  width: 100%;
  max-height: 600px;
  overflow-y: auto;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

.chatarea-box::-webkit-scrollbar {
  width: 7px;
  background: transparent;
  
}

.chatarea-box::-webkit-scrollbar-track {
  background: #dfe1e5;
  border-radius: 24px;
  margin-top: 15px;
  margin-bottom: 5px;
}

.chatarea-box::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #b5b5b5;
}

.display-header {
  height: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: #fff;
}

.sample-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 5px;
  border: solid 1px red;
}

.sample-button-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 60%;
  margin-bottom: 7px;
  border: 1px solid red;
}

.sample-prompts-button {
  background: #f8f9fa;
  border: 1px solid #dfe1e5;
  text-align: center;
  
  border-radius: 6px;
  color: #515151;
  height: 50px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  
}

.sample-prompts-button:hover{
  background-color: #f8f9fa;
  box-shadow: 0 1px 6px rgba(32,33,36,.28);
  border-color: rgba(223,225,229,0);
}

.sample-prompts-button:focus {
  border: 1px solid #dfe1e5;
  outline: none;
}
/* input/button inside input */

.input-form {
  display:flex;
  flex-direction:row;
  padding: 6px 6px;
  border-radius: 24px;
  background: #fff;
  box-shadow: 3px 3px 3px 0.5 rgba(0, 0, 0, 0.5);
}

.input-gray {
  flex-grow:2;
  border:none;
  background: #fff;
  color: #515151;
  resize: none;
}

.input-gray::placeholder {
  color: #afb3b8;
  font-size: 14px;
}

.input-gray:focus {
  outline: none;
}

.input-gray::-webkit-scrollbar {
  width: 7px;
}

.input-gray::-webkit-scrollbar-track {
  background: #dfe1e5;
  border-radius: 24px;
  
}

.input-gray::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #b5b5b5;
}

.input-form:focus-within { 
  outline: none;
}

.chat-button {
  border: none;
  border-radius: 20px;
  background:#2870ea;
  color:white;
  width: 45px;
  background-image: url("https://predanalytics-assets.s3.amazonaws.com/send.png");
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: center center;
}

.chat-button:focus {
  outline: none;
}

.chat-icon {
  border: none;
  border-radius: 20px;
  background:none;
  color:white;
  width: 35px;
  background-image: url("https://predanalytics-assets.s3.amazonaws.com/chaticon.png");
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: center center;
}

.nav-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.nav-box {
  display: flex;
  flex-direction: row;
  align-items: right;
  justify-content: right;
  margin: 10px;
  width: 60%;
}

.nav-item {
  margin-left: 20px;
}

.footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.cancel-prompt-button {
  border: none;
  border-radius: 4px;
  border: 1px solid #dfe1e5;
  background:#fff;
  color:#343a40;
  width: auto;
  margin-left: 20px;
  padding: 5px;
}

.cancel-prompt-button:focus {
  outline: none;
}

.cancel-prompt-button:hover{
  background-color: #fff;
  box-shadow: 0 1px 6px rgba(32,33,36,.28);
  border-color: rgba(223,225,229,0);
}

/* NEW SAMPLE PROMPTS TEST */
.sprompt-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  flex-direction: column;
}

.sprompt-title {
  width: 70%;
  font-size: 14px;
  margin-bottom: 5px;
}

.sprompt-info {
  width: 70%;
  font-size: 14px;
  margin-bottom: 10px;
}

.sprompt-parent {
  width: 60%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.sprompt-child {
  flex: 1 30%;
  min-width: 100px;
  margin: 5px;
  margin-bottom: 5px;
}

/* MOBILE COMPAT */
@media only screen and (max-width: 768px) {
  .grid-container {
    grid-template-rows: auto auto auto auto auto; 
    grid-template-columns: 1fr; 
    height: 100vh;
    width: 100vw;
    
  }

  .header {
    padding: 0;
  }

  .chat {
    padding: 0;
  }

  .sample-prompts {
    padding: 0;
  }

  .display-box {
    width: 90%; 
    border-radius: 10px; 
    font-size: 13px;
    margin-bottom: 5px;
  }

  .sample-button-container {
    width: 100vw;
    
  }

  .sample-button-box {
    width: 90%;
  }

  .sample-prompts-button {
    font-size: 12px;
  }

  .header-container {
    text-align: left; 
    padding: 10px; 
    width: 100vw;
  }

  .display-container {
    width: 100vw;
  }

  .logo {
    width: 160px;
    height: 80px;
    background-image: url("https://predanalytics-assets.s3.amazonaws.com/NewLogo.webp");
    /*background-image: url("https://predanalytics-assets.s3.amazonaws.com/logotest.webp"); */
    background-repeat: no-repeat;
    background-size: contain;
    
  }

  .banner{
    margin-top: -44px;
    margin-bottom: -80px;
  }

  .banner > img {
    height: 480px;
  }

  .motto {
    font-size: 14px; /* Adjust font size */
    margin-bottom: 20px; /* Example: Add more space between elements */
  }

  .display-box {
    border-radius: 20px; /* Example: Adjust padding for better readability */
  }
  
  .info {
    text-align: center;
    font-size: 14px;
    padding: 10px;
    
  }

  .prompt {
    padding: 5px;
  }

  .answer {
    padding: 5px;
  }
}

